import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="max-w-1/2 mx-auto" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Privacy Policy</h1>
            <p>
                <strong>Last Updated:</strong> 2023-08-14
            </p>

            <p>
                Adas Solutions ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy
                explains how we collect, use, and protect the personal information of technicians who sign up to use our
                services.
            </p>

            <h2 className="text-lg bold my-4">1. Information We Collect</h2>
            <p>
                When you sign up with Adas Solutions, we collect personal information that you provide to us, such as
                your name, phone number, and other contact details. This information is necessary for us to create and
                manage your account and provide you with our services.
            </p>

            <h2 className="text-lg bold my-4">2. Use of Information</h2>
            <p>We use the personal information you provide to:</p>
            <ul>
                <li>Create and manage your technician account.</li>
                <li>
                    Communicate with you regarding job assignments, service updates, and other relevant notifications.
                </li>
                <li>Send message alerts related to your assignments, reminders, or updates.</li>
            </ul>

            <h2 className="text-lg bold my-4">3. Message Alerts</h2>
            <p>
                By signing up for our services, you consent to receive message alerts from Adas Solutions. These alerts
                may include notifications about new assignments, status updates, and important service information.
            </p>

            <h2 className="text-lg bold my-4">4. Unsubscribing from Message Alerts</h2>
            <p>
                You have the option to unsubscribe from receiving message alerts at any time. To unsubscribe, follow the
                instructions provided in the message or contact us at{' '}
                <a href="mailto:cmayer@adassolutionsusa.com">cmayer@adassolutionsusa.com</a>. Please note that
                unsubscribing from message alerts may impact your ability to receive important updates related to your
                assignments.
            </p>

            <h2 className="text-lg bold my-4">5. Data Security</h2>
            <p>
                We take reasonable measures to protect the personal information you provide from unauthorized access,
                loss, or misuse.
            </p>

            <h2 className="text-lg bold my-4">6. Changes to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page with an
                updated date. We encourage you to review this policy periodically.
            </p>

            <h2 className="text-lg bold my-4">7. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy or how we handle your personal
                information, please contact us at{' '}
                <a href="mailto:cmayer@adassolutionsusa.com">cmayer@adassolutionsusa.com</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
