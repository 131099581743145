import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import StatusPage from '../../Pages/StatusPage';
import OrdersPage from '../../Pages/OrdersPage';
import VacationPage from '../../Pages/VacationPage';
import SettingsPage from '../../Pages/SettingsPage';
import SignupPage from '../../Pages/SignupPage';
import LoginPage from '../../Pages/LoginPage';
import OpenJobPage from '../../Pages/OpenJobPage';
import Navigation from '../Navigation';
import { AuthContext } from '../../Providers/UserProvider';
import LoaderComponent from '../Loader';
import ProjectsListPage from '../../Pages/ProjectsListPage';
import ProjectDetailPage from '../../Pages/ProjectDetailPage';
import PrivacyPolicy from '../../Pages/PrivacyPolicy';

function Router() {
    const { techId, user, initialized } = useContext(AuthContext);

    return (
        <div>
            {initialized ? (
                <>
                    <BrowserRouter>
                        {techId && user ? (
                            <>
                                <Navigation />
                                <Switch>
                                    <Route path="/open-jobs/:regionId/:techId/:jobId" component={OpenJobPage} />
                                    <Route path="/app/status" component={StatusPage} />
                                    <Route path="/app/orders" component={OrdersPage} />
                                    <Route path="/app/vacation" component={VacationPage} />
                                    <Route path="/app/settings" component={SettingsPage} />
                                    <Route
                                        path="/app/projects/:projectId/:techProjectId"
                                        component={ProjectDetailPage}
                                    />
                                    <Route path="/app/projects" component={ProjectsListPage} />
                                    <Redirect to="/app/status" />
                                </Switch>
                            </>
                        ) : (
                            <Switch>
                                <Route path="/open-jobs/:regionId/:techId/:jobId" component={OpenJobPage} />
                                <Route path="/signup" component={SignupPage} />
                                <Route path="/privacy-policy" component={PrivacyPolicy} />
                                <Route path="/login" component={LoginPage} />
                                <Redirect to={{ pathname: '/login' }} />
                            </Switch>
                        )}
                    </BrowserRouter>
                </>
            ) : (
                <LoaderComponent showLoader />
            )}
        </div>
    );
}
export default Router;
