import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import * as qs from 'query-string';
import { auth, db, functions } from '../../firebase';
import LoaderComponent from '../../Shared/Loader';
import logo from '../../Assets/Images/logo.png';

export interface ILocation {
    search: string;
    hash: string;
    key: string;
    pathname: string;
    state: string;
}

interface Props {
    location: ILocation;
}

const SignupPage: FunctionComponent<Props> = ({ location }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [techRegion, setTechRegion] = useState('');
    const [availableRegions, setAvailableRegions] = useState<Array<{ name: string; center: any; id: string }>>([]);
    const [showLoader, setShowLoader] = useState(false);
    const [isRegionIdSupplied, setIsRegionIdSupplied] = useState(false);
    const [acceptsMessages, setAcceptsMessages] = useState(false); // Added state for message alerts opt-in

    const createUserWithEmailAndPasswordHandler = async (event) => {
        setShowLoader(true);
        event.preventDefault();
        try {
            if (!acceptsMessages) {
                setError('You must agree to receive message alerts to proceed.');
                setShowLoader(false);
                return;
            }

            await functions.httpsCallable('registerTech')({
                email,
                password,
                regionId: techRegion,
                name,
                phone,
            });
            await auth.signInWithEmailAndPassword(email, password);
            setShowLoader(false);
        } catch (errors) {
            setError(errors.message);
            setShowLoader(false);
        }
    };

    const onChangeHandler = (setFunc: (value: any) => void) => (evt) => setFunc(evt.currentTarget.value);

    // Need to fetch regions right away
    useEffect(() => {
        return db.collection(`/regions`).onSnapshot((regionSnapshot) => {
            setAvailableRegions(regionSnapshot.docs.map((doc) => ({ ...(doc.data() as any), id: doc.id })));
        });
    }, [setAvailableRegions]);

    const queryRegionMatch = useMemo(() => {
        const { regionID } = qs.parse(location.search);
        return regionID && availableRegions?.find((region) => region.id === regionID);
    }, [availableRegions, location]);

    useEffect(() => {
        if (queryRegionMatch) {
            setTechRegion(queryRegionMatch.id);
            setIsRegionIdSupplied(true);
            setError('');
        } else if (qs.parse(location.search)?.regionID) {
            setError("This link is invalid. Please contact an administrator if you don't know your region");
        } else {
            setError('');
        }
    }, [location, queryRegionMatch]);

    return (
        <div className="bg-cover min-h-screen bg-gradient-to-b from-black to-blue-500">
            <LoaderComponent showLoader={showLoader} />
            <div className="flex flex-col">
                <div className="mt-32 text-center mx-auto">
                    <img src={logo} alt="Logo" className="text-center mx-auto w-8/12" />
                    <p className="text-lg  font-medium text-center text-white mt-4">Technician Scheduling Made Easy</p>
                </div>
                <div className="text-center  mt-16">
                    {error && <div className="py-4 bg-red-500 w-full text-white text-center mb-3">{error}</div>}
                    <form
                        className="flex-col"
                        onSubmit={(event) => {
                            createUserWithEmailAndPasswordHandler(event);
                        }}
                    >
                        <div className="my-4">
                            <input
                                required
                                type="text"
                                name="name"
                                value={name}
                                id="name"
                                onChange={onChangeHandler(setName)}
                                placeholder="Name"
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>
                        <div className="my-4">
                            <input
                                required
                                type="email"
                                name="userEmail"
                                value={email}
                                placeholder="Email"
                                id="userEmail"
                                onChange={onChangeHandler(setEmail)}
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>
                        <div className="my-4">
                            <input
                                required
                                type="number"
                                name="phone"
                                value={phone}
                                id="phone"
                                placeholder="Phone Number"
                                onChange={onChangeHandler(setPhone)}
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>
                        <div className="my-4">
                            <input
                                required
                                type="password"
                                name="userPassword"
                                value={password}
                                placeholder="Password"
                                id="userPassword"
                                onChange={onChangeHandler(setPassword)}
                                className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                            />
                        </div>

                        {!isRegionIdSupplied && (
                            <div className="my-4">
                                <select
                                    className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                                    required
                                    value={techRegion}
                                    onChange={onChangeHandler(setTechRegion)}
                                >
                                    <option className="placeholder-gray-600" value="">
                                        Select a region
                                    </option>
                                    {availableRegions.map((region) => (
                                        <option key={region.id} value={region.id}>
                                            {region.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {/* Opt-in Checkbox for Message Alerts */}
                        <div className="my-4">
                            <label className="text-white">
                                <input
                                    required
                                    type="checkbox"
                                    checked={acceptsMessages}
                                    onChange={() => setAcceptsMessages(!acceptsMessages)}
                                    className="mr-2"
                                />
                                I agree to receive message alerts regarding my assignments. I understand I can
                                unsubscribe at any time.
                            </label>
                        </div>

                        <div className="mt-8">
                            <button
                                type="submit"
                                className="bg-primaryTheme hover:bg-blue-900 text-white font-bold py-2 px-4 rounded w-72"
                            >
                                Register
                            </button>
                        </div>
                    </form>
                    <div className="my-4 text-white">
                        <p>
                            <NavLink to="/login">Already have an account?</NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupPage;
